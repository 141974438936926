import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import { assignEdulasticTest } from './amplify/assignEdulasticTest';
import { createStudentRosterEntry } from './amplify/createStudentRosterEntry';
import { createUpdateUser } from './amplify/createUpdateUser';
import { deleteAppeal } from './amplify/deleteAppeal';
import { deleteInfraction } from './amplify/deleteInfraction';
import { deleteUser } from './amplify/deleteUser';
import { getAntiPatternList } from './amplify/getAcademicsAntipatterns';
import { getAllDays } from './amplify/getAllDays';
import { getAllSkills } from './amplify/getAllSkills';
import { getAllWeeks } from './amplify/getAllWeeks';
import { getAntiPatterns } from './amplify/getAntiPatterns';
import { getAppeals } from './amplify/getAppeals';
import { getApps } from './amplify/getApps';
import { getClipReviewPrompt } from './amplify/getClipReviewPrompt';
import { getHowToVideo } from './amplify/getHowToVideo';
import { getInfractions } from './amplify/getInfractions';
import { getNextBestTestData } from './amplify/getNextBestTestData';
import { getSiteConfig } from './amplify/getSiteConfig';
import { getSkillPlan } from './amplify/getSkillPlan';
import { getStudentCoaching } from './amplify/getStudentCoaching';
import { getStudentLearningActivity } from './amplify/getStudentLearningActivity';
import { getStudentLearningTime } from './amplify/getStudentLearningTime';
import { getStudentRoster } from './amplify/getStudentRoster';
import { getStudents } from './amplify/getStudents';
import { getStudentsByCampus } from './amplify/getStudentsByCampus';
import { getStudentsForTestAssignment } from './amplify/getStudentsForTestAssignment';
import { getTimeAndActivity } from './amplify/getTimeAndActivity';
import { getUserList } from './amplify/getUserList';
import { postAppeal } from './amplify/postAppeal';
import { postInfraction } from './amplify/postInfraction';
import { putAppeal } from './amplify/putAppeal';
import { searchRecommendations } from './amplify/searchRecommendations';
import { SimpleCrudGetList } from './amplify/simpleCrudGetList';
import { createRecommendationOverrides } from './amplify/submitOverrides';
import { submitTests } from './amplify/submitTests';
import { updateSkillPlan } from './amplify/updateSkillPlan';

import { getCharts } from './amplify/check-charts/getCharts';
import { getSessionSnapshot } from './amplify/getSessionSnapshot';

import { getCourseList } from '@common/utils/amplify/getCourseList';
import { getStudentClipReviewCoaching } from '@common/utils/amplify/getStudentClipReviewCoaching';
import { ClipReview } from '../@types/clip_review';
import { Coaching } from '../@types/coaching';
import { Override } from '../@types/override';
import { addSkill } from './amplify/addSkill';
import { assignMapTest } from './amplify/assignMapTest'; // AI-GEN - Cursor - Claude3 - Opus
import { createAntipattern } from './amplify/createAntipattern';
import { deleteAntipattern } from './amplify/deleteAntipattern';
import { deletePostCoachingForm } from './amplify/deletePostCoachingForm';
import { editSkill } from './amplify/editSkill';
import { getActiveSubjectsByMultipleStudentIds } from './amplify/getActiveSubjectsByMultipleStudentIds';
import { getActiveSubjectsByStudentId } from './amplify/getActiveSubjectsByStudentId'; // AI-GEN - Cursor - GPT4
import { getAllCombinedSkills } from './amplify/getAllCombinedSkill';
import { fetchAndSaveAutoLoginTokens } from './amplify/getAutoLoginTokens';
import { getCoachingSummary } from './amplify/getCoachingSummary';
import { getCoachingUrls } from './amplify/getCoachingUrls';
import { getCompetitionOnboardingStatus } from './amplify/getCompetitionOnboardingStatus';
import { getEdulasticTests } from './amplify/getEdulasticTests';
import { getGoalDetails } from './amplify/getGoalDetails'; // AI-GEN - Cursor - GPT4
import { getGoals } from './amplify/getGoals'; // AI-GEN - Cursor - GPT4
import { getMapStatus } from './amplify/getMapStatus'; // AI-GEN - Cursor - Claude3 - Opus
import { getMasteryThresholds } from './amplify/getMasteryThresholds';
import { getPostCoachingAntiPattern } from './amplify/getPostCoachingAntiPattern';
import { getPostCoachingFormById } from './amplify/getPostCoachingFormById';
import { getPostCoachingFormOptions } from './amplify/getPostCoachingFormOptions';
import { getPostCoachingListing } from './amplify/getPostCoachingListing';
import { getRealtimeDailyActivity } from './amplify/getRealtimeDailyActivity';
import { getSkillContent } from './amplify/getSkillContent';
import { getSkills } from './amplify/getSkills';
import { getStudentGoalByStudentId } from './amplify/getStudentGoalByStudentId'; // AI-GEN - Cursor - GPT4
import { getStudentGoalTargets } from './amplify/getStudentGoalTargets';
import { getTestDetailsByAssignmentId } from './amplify/getTestDetails';
import { getTestingSubjects } from './amplify/getTestingSubjects';
import { getTestScores } from './amplify/getTestScores';
import { getTimeframes } from './amplify/getTimeframes'; // AI-GEN - Cursor - GPT4
import { getTwoHourOnboardingStatus } from './amplify/getTwoHourOnboardingStatus';
import { invalidateGoal } from './amplify/invalidateGoal'; // AI-GEN - Cursor - GPT4
import { putCompetitionOnboardingStatus } from './amplify/putCompetitionOnboardingStatus';
import { putKhanStatus } from './amplify/putKhanStatus';
import { putMapStatus } from './amplify/putMapStatus'; // AI-GEN - Cursor - Claude3 - Opus
import { putTwoHourOnboardingStatus } from './amplify/putTwoHourOnboardingStatus';
import { reportQuestion } from './amplify/reportQuestion';
import { saveGoal } from './amplify/saveGoal'; // AI-GEN - Cursor - GPT4
import { submitPostCoachingForm } from './amplify/submitPostCoachingForm';
import { unblockRecommendation } from './amplify/unblockRecommendation';
import { putAntipattern } from './amplify/updateAntipattern';
import { updateCoachingSummaryResponse } from './amplify/updateCoachingSummaryResponse';
import { updateGoal } from './amplify/updateGoal'; // AI-GEN - Cursor - GPT4
import { updateSkillContent } from './amplify/updateSkillContent';
import { voluntaryTestOut } from './amplify/voluntaryTestOut';
import { FetchStatus } from './constant';
import { getSiteStrings } from './getSiteStrings';

export type SafeMutation = {
  onSuccess: () => void;
  onError: (error: any) => void;
};

// `.bind` calls are necessary to ensure `this.` references work
const coaching = new SimpleCrudGetList<Coaching>('coaching');
const clipReview = new SimpleCrudGetList<ClipReview>('clip_review');
const override = new SimpleCrudGetList<Override>('override');
export const trpc = {
  siteConfig: {
    get: {
      useQuery: () => useQueryWrapper(getSiteConfig, null),
    },
  },
  siteStrings: {
    get: {
      useQuery: () => useQueryWrapper(getSiteStrings, null),
    },
  },
  sessionSnapshot: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getSessionSnapshot, input, config),
    },
  },
  checkCharts: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getCharts, input, config),
    },
  },
  academicAntipatterns: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getAntiPatternList, input, config),
    },
    post: {
      useMutation: () => ({
        mutate: (input: any) => createAntipattern(input),
      }),
    },
    delete: {
      useMutation: ({ onSuccess, onError }: SafeMutation) => ({
        mutate: (input: any) => deleteAntipattern(input).then(onSuccess).catch(onError),
      }),
    },
    update: {
      useMutation: () => ({ mutate: (input: any) => putAntipattern(input) }),
    },
  },
  coaching: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(coaching.get.bind(coaching), input, config),
    },
    list: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(coaching.list.bind(coaching), input, config),
    },
    getLinks: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getCoachingUrls, input, config),
    },
  },
  postCoachingForm: {
    getOptions: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getPostCoachingFormOptions, input, config),
    },
    submit: {
      useMutation: () => ({ mutate: (input: any) => submitPostCoachingForm(input) }),
    },
    reportQuestion: {
      useMutation: () => ({ mutate: (input: any) => reportQuestion(input) }),
    },
    getListing: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getPostCoachingListing, input, config),
    },
    getFormById: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getPostCoachingFormById, input, config),
    },
    getTestDetails: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getTestDetailsByAssignmentId, input, config),
    },
    deleteForm: {
      useMutation: () => ({ mutate: (input: any) => deletePostCoachingForm(input) }),
    },
    getAntiPatterns: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getPostCoachingAntiPattern, input, config),
    },
  },

  clipReview: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(clipReview.get.bind(clipReview), input, config),
    },
    list: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(clipReview.list.bind(clipReview), input, config),
    },
    getPrompt: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getClipReviewPrompt, input, config),
    },
  },
  override: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(override.get.bind(override), input, config),
    },
    list: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(override.list.bind(override), input, config),
    },
  },
  mergedTimeAndActivity: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getTimeAndActivity, input, Object.assign({ retryable: true }, config)),
    },
  },
  studentLearningTime: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getStudentLearningTime, input, Object.assign({ retryable: true }, config)),
    },
  },
  testScores: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getTestScores, input, Object.assign({ retryable: true }, config)),
    },
  },
  edulasticTests: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getEdulasticTests, input, Object.assign({ retryable: true }, config)),
    },
  },
  studentCoaching: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getStudentCoaching, input, config),
    },
  },
  studentClipReviewCoaching: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getStudentClipReviewCoaching, input, config),
    },
  },
  antipatterns: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getAntiPatterns, input, config),
    },
  },
  apps: {
    get: {
      useQuery: (input: any) => useQueryWrapper(getApps, input),
    },
    getHowToVideo: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getHowToVideo, input, config),
    },
  },
  subjects: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getTestingSubjects, input, config),
    },
  },
  allDays: {
    get: {
      useQuery: (input: any) => useQueryWrapper(getAllDays, input),
    },
    getAllWeeks: {
      useQuery: (input: any) => useQueryWrapper(getAllWeeks, input),
    },
  },
  masterRoster: {
    getStudents: {
      useQuery: (input?: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getStudents, input, config),
    },
  },
  userList: {
    get: {
      useQuery: (input: any) => useQueryWrapper(getUserList, input),
    },
    post: {
      useMutation: () => ({
        mutate: (input: any, isEdit: boolean) => createUpdateUser(input, isEdit),
      }),
    },
    delete: {
      useMutation: () => ({ mutate: (input: any) => deleteUser(input) }),
    },
  },
  studentLearningActivity: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getStudentLearningActivity, input, config),
    },
  },
  getStudentRealtimeActivity: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getRealtimeDailyActivity, input, config),
    },
  },
  masteryThresholds: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getMasteryThresholds, input, config),
    },
  },
  voluntaryTestOut: {
    post: {
      useMutation: () => ({
        mutate: (input: any) => voluntaryTestOut(input),
      }),
    },
  },
  competitionOnboardingStatus: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getCompetitionOnboardingStatus, input, config),
    },
    put: {
      useMutation: () => ({
        mutate: (input: any) => putCompetitionOnboardingStatus(input),
      }),
    },
    putKhanStatus: {
      useMutation: () => ({
        mutate: (input: any) => putKhanStatus(input),
      }),
    },
  },
  twoHourOnboardingStatus: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getTwoHourOnboardingStatus, input, config),
    },
    put: {
      useMutation: () => ({
        mutate: (input: any) => putTwoHourOnboardingStatus(input),
      }),
    },
  },
  infractions: {
    findAllInfractions: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getInfractions, input, config),
    },
    createInfraction: {
      useMutation: () => ({ mutate: (input: any) => postInfraction(input) }),
    },
    deleteInfractions: {
      useMutation: ({
        onSuccess,
        onError,
      }: {
        onSuccess: () => void;
        onError: (error: any) => void;
      }) => ({ mutate: (input: any) => deleteInfraction(input).then(onSuccess).catch(onError) }),
    },
  },
  appeals: {
    findAllAppeals: {
      useQuery: (input: any, config?: { enabled: boolean } | null) =>
        useQueryWrapper(getAppeals, input, config),
    },
    createAppeal: {
      useMutation: () => ({ mutate: (input: any) => postAppeal(input) }),
    },
    updateAppeal: {
      useMutation: () => ({ mutate: (input: any) => putAppeal(input) }),
    },
    deleteAppeals: {
      useMutation: ({
        onSuccess,
        onError,
      }: {
        onSuccess: () => void;
        onError: (error: any) => void;
      }) => ({ mutate: (input: any) => deleteAppeal(input).then(onSuccess).catch(onError) }),
    },
  },
  recommendations: {
    submitTests: {
      useMutation: () => ({ mutate: (input: any) => submitTests(input) }),
    },
    submitOverrides: {
      useMutation: () => ({ mutate: (input: any) => createRecommendationOverrides(input) }),
    },
    unblock: {
      useMutation: () => ({ mutate: (input: any) => unblockRecommendation(input) }),
    },
    getAllSkills: {
      useQuery: (config?: useQueryWraperConfig | null) => useQueryWrapper(getAllSkills, config),
    },
    getAllCombinedSkills: {
      useQuery: (config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getAllCombinedSkills, config),
    },
    search: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(searchRecommendations, input, config),
    },
  },
  testing: {
    getStudentsForAssignment: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getStudentsForTestAssignment, input, config),
    },
    assignEdulasticTest: {
      useMutation: () => ({ mutate: (input: any) => assignEdulasticTest(input) }),
    },
    getNextBestTestData: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getNextBestTestData, input, config),
    },
    getStudentsByCampus: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getStudentsByCampus, input, config),
    },
    map: {
      assignMapTest: {
        useMutation: () => ({ mutate: (input: any) => assignMapTest(input) }), // AI-GEN - Cursor - Claude3 - Opus
      },
      get: {
        useQuery: (input: any, config?: useQueryWraperConfig | null) =>
          useQueryWrapper(getMapStatus, input, config), // AI-GEN - Cursor - Claude3 - Opus
      },
      put: {
        useMutation: () => ({ mutate: (input: any) => putMapStatus(input) }), // AI-GEN - Cursor - Claude3 - Opus
      },
    },
  },
  management: {
    getStudentRoster: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getStudentRoster, input, config),
    },
    createStudentRosterEntry: {
      useMutation: () => ({ mutate: (input: any) => createStudentRosterEntry(input) }),
    },
    getSkillPlan: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getSkillPlan, input, config),
    },
    updateSkillPlan: {
      useMutation: () => ({ mutate: (input: any) => updateSkillPlan(input) }),
    },
    getCourseList: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getCourseList, input, config),
    },
    skills: {
      get: {
        useQuery: (config?: useQueryWraperConfig | null) =>
          useQueryWrapper(getSkills, null, config),
      },
      add: {
        useMutation: () => ({
          mutate: (input: any) => addSkill(input),
        }),
      },
      edit: {
        useMutation: () => ({
          mutate: (input: { data: any; skillId: string }) => editSkill(input.data, input.skillId),
        }),
      },
    },
  },
  coachingSummary: {
    get: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getCoachingSummary, input, config),
    },
    updateResponse: {
      useMutation: () => ({ mutate: (input: any) => updateCoachingSummaryResponse(input) }),
    },
  },
  goals: {
    invalidate: {
      useMutation: () => ({ mutate: (input: any) => invalidateGoal(input) }), // AI-GEN - Cursor - GPT4
    },
    save: {
      useMutation: () => ({ mutate: (input: any) => saveGoal(input) }), // AI-GEN - Cursor - GPT4
    },
    update: {
      useMutation: () => ({ mutate: (input: any) => updateGoal(input) }), // AI-GEN - Cursor - GPT4
    },
    getDetails: {
      useQuery: (
        input: { studentId: string; subject: string; goalId: string },
        config?: useQueryWraperConfig | null // AI-GEN - Cursor - GPT4
      ) =>
        useQueryWrapper(
          (input) => getGoalDetails(input.studentId, input.subject, input.goalId),
          input,
          config
        ), // AI-GEN - Cursor - GPT4
    },
    getAll: {
      useQuery: (config?: useQueryWraperConfig | null) => useQueryWrapper(getGoals, null, config), // AI-GEN - Cursor - GPT4
    },
    getTimeframes: {
      useQuery: (config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getTimeframes, null, config), // AI-GEN - Cursor - GPT4
    },
    getStudentGoalByStudentId: {
      useQuery: (
        input: any,
        config?: useQueryWraperConfig | null // AI-GEN - Cursor - GPT4
      ) => useQueryWrapper(getStudentGoalByStudentId, input, config), // AI-GEN - Cursor - GPT4
    },
    getActiveSubjectsByStudentId: {
      useQuery: (
        input: any,
        config?: useQueryWraperConfig | null // AI-GEN - Cursor - GPT4
      ) => useQueryWrapper(getActiveSubjectsByStudentId, input, config), // AI-GEN - Cursor - GPT4
    },
    getActiveSubjectsByMultipleStudentIds: {
      useQuery: (
        input: any,
        config?: useQueryWraperConfig | null // AI-GEN - Cursor - GPT4
      ) => useQueryWrapper(getActiveSubjectsByMultipleStudentIds, input, config), // AI-GEN - Cursor - GPT4
    },
    getStudentGoalTargets: {
      useQuery: (
        input: any,
        config?: useQueryWraperConfig | null // AI-GEN - Cursor - GPT4
      ) => useQueryWrapper(getStudentGoalTargets, input, config), // AI-GEN - Cursor - GPT4
    },
  },
  skillContent: {
    getAll: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(getSkillContent, input, config),
    },
    update: {
      mutate: (input: any) => updateSkillContent(input),
    },
  },
  studyReel: {
    getAutoLoginToken: {
      useQuery: (input: any, config?: useQueryWraperConfig | null) =>
        useQueryWrapper(fetchAndSaveAutoLoginTokens, input, config),
    },
  },
};

interface useQueryWraperConfig {
  enabled?: boolean;
  retryable?: boolean; // set to false to let it rerun a few times in case of network failure/5xx
  pollInterval?: number; // in milliseconds
  skipLoadingScreen?: boolean; // will prevent `isLoading` from being toggled back to true to avoid UI loading screen flashes
  syncWithVisibility?: boolean; // will trigger data refresh when the user returns to the page
  snackbarErrors?: boolean; // will show a snackbar with the error message
  mostRecentOnly?: boolean; // will only return the most recently requested data
}

// amplifyConfig type is actually what they use internally
function useQueryWrapper<T, U>(
  fetchData: (input: T, amplifyConfig?: { [key: string]: any }) => Promise<U>,
  input: T,
  config?: useQueryWraperConfig | null
) {
  const [data, setData] = useState<U | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>('preparing');
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let fetchError = false;

    const fetchDataAsync = async (skipLoading = false) => {
      // There are cases where we don't want to loading flag to be set,
      // like reloading data while the user is in the middle of a page
      if (!skipLoading) setIsLoading(true);
      setFetchStatus('fetching');

      let attempt = 0;
      let lastError;
      const maxAttempts = config?.retryable ? 3 : 1;
      while (true) {
        try {
          const amplifyOptions: { [key: string]: any } = {};
          if (config?.mostRecentOnly) amplifyOptions.cancellableToken = source.token;
          const result = await fetchData(input, amplifyOptions);

          setData(result);
          break;
        } catch (error) {
          lastError = error;

          attempt++;

          // If we got a non-network/non-5xx error, or we've already tried the max times, stop trying
          const isNetworkError = error.message === 'Network Error';
          const is5xxError = error.message
            .match(/failed with status code ([0-9]+)/)?.[1]
            ?.startsWith('5');
          if ((!isNetworkError && !is5xxError) || attempt >= maxAttempts) {
            fetchError = true;
            console.error(`API call failed after ${attempt} attempts`);
            break;
          }

          const ms = 250 * attempt;
          console.error(
            `API failure (was a "Network Error": ${isNetworkError}; was a 5xx: ${is5xxError}), waiting ${ms}ms`
          );
          await new Promise((resolve) => window.setTimeout(resolve, ms));
        }
      }
      if (fetchError || attempt === maxAttempts) {
        if (config?.snackbarErrors) {
          enqueueSnackbar(lastError.message, { variant: 'error' });
        }
        setFetchStatus('errored');
      } else {
        setFetchStatus('fetched');
        setInitialLoadComplete(true);
      }
      setIsLoading(false);
    };

    setData(undefined);
    console.log('useQueryWrapper', JSON.stringify(input), config?.enabled);

    let pollWindowInterval: number;
    let handleVisibilityChange: () => void;
    let isEventListenerAdded = false;

    // How to actually trigger the load:
    if (config?.enabled ?? true) {
      // Always load once
      fetchDataAsync();

      // If the config specified a polling interval:
      if (config?.pollInterval) {
        // Set up a background processing interval
        pollWindowInterval = window.setInterval(() => {
          fetchDataAsync(config.skipLoadingScreen ?? false);
        }, config.pollInterval);
      }

      if (config?.syncWithVisibility && !isEventListenerAdded) {
        handleVisibilityChange = () => {
          if (!document.hidden) {
            fetchDataAsync(config.skipLoadingScreen ?? false);
          }
        };
        isEventListenerAdded = true;
        document.addEventListener('visibilitychange', handleVisibilityChange);
      }

      // Returning a function from `useEffect` has it called on unmount - this
      // will effectively stop the polling loads once the user leaves the page
      return () => {
        if (config?.pollInterval) {
          window.clearInterval(pollWindowInterval);
        }
        if (config?.syncWithVisibility) {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        }
      };
    }

    // Cleanup function
    return () => {
      source.cancel('Operation canceled by the user.');
    };
  }, [JSON.stringify(input), config?.enabled]);

  return { data, isLoading, initialLoadComplete, fetchStatus };
}
